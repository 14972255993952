.pricingSideBySide {
  composes: grid from '~g';
  padding-bottom: 12.4rem;
}

.container {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 2.4rem;
  grid-column: start / end;

  @media (--sm-only) {
    flex-direction: column;
  }
}
