.container {
  position: relative;
  overflow: hidden;
  padding: var(--spacer-24);
  border: 1px solid rgb(157 157 255 / 5%);
  border-radius: var(--border-radius-10);
  background: var(--grey-brand-grey-08, #12131d);
  box-shadow:
    0 100px 80px 0 rgb(0 0 0 / 19%),
    0 64.815px 46.852px 0 rgb(0 0 0 / 14%),
    0 38.519px 25.481px 0 rgb(0 0 0 / 12%),
    0 20px 13px 0 rgb(0 0 0 / 9%),
    0 8.148px 6.519px 0 rgb(0 0 0 / 7%),
    0 1.852px 3.148px 0 rgb(0 0 0 / 5%);

  @media (--md) {
    flex: 50%;
    padding: 3.6rem;
  }

  @media (--lg) {
    padding: 5rem;
  }
}

.background {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.backgroundMedia,
.backgroundMediaContainer {
  width: 100%;
  height: 100%;
}

.content {
  position: relative;
  z-index: 2;
}

.header:not(.headerWithSpacer) {
  @media (--md) {
    padding-bottom: var(--spacer-24);
    border-bottom: 1px solid var(--grey-brand-grey-15, #252736);
  }
}

.headerWithSpacer {
  padding-bottom: var(--spacer-24);
  border-bottom: 1px solid var(--grey-brand-grey-15, #252736);
  margin-bottom: var(--spacer-32);
}

.eyebrow {
  composes: eyebrow from '~typo';
  height: 1lh;
  margin-bottom: var(--spacer-16);
  color: var(--color-accent);

  @media (--md) {
    margin-bottom: var(--spacer-16);
  }
}

.title {
  composes: pricingOverviewCardPrice from '~typo';
  margin-right: var(--spacer-8);
  color: var(--grey-brand-grey-100, #fcfcfc);
}

.subtitle {
  composes: pricingOverviewCardPrice from '~typo';
  margin-right: var(--spacer-8);
  color: var(--grey-brand-grey-60, #81859f);
}

.label {
  composes: label from '~typo';
  margin-top: var(--spacer-6);
  color: var(--grey-brand-grey-60, #81859f);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.bulletsTitle {
  composes: body from '~typo';
  margin-bottom: 1.2rem;
  color: var(--grey-brand-grey-100, #fcfcfc);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.72;
  line-height: 0.9;
  text-transform: uppercase;
}

.bulletsOverflowList {
  overflow: hidden;
  max-height: 0;
  margin-top: var(--spacer-4);
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top center;
  transition:
    transform 0.3s var(--ease-basic-butter),
    opacity 0.3s var(--ease-basic-butter),
    max-height 0.25s var(--ease-basic-butter);
}

.bulletsOverflowListExpanded {
  max-height: 20rem;
  opacity: 1;
  transform: scaleY(1);
}

.bulletItem {
  composes: body from '~typo';
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--alpha-brand-alpha-light-90, rgb(241 244 255 / 90%));
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.45;

  &:not(:last-of-type) {
    margin-bottom: 0.3rem;
  }
}

.bulletItemsViewAllLink {
  display: flex;
  align-items: center;
  margin-top: var(--spacer-12);

  .viewAllLinkChevron {
    margin-left: var(--spacer-10);
    fill: var(--grey-brand-grey-100, #fcfcfc);
    transform-origin: center;
    transition: transform 0.2s var(--ease-basic-butter);
  }

  .viewAllLinkChevron[data-expanded='true'] {
    transform: rotate(180deg);
  }
}

.bulletItemCaret {
  display: inline-block;
  width: 8.5px;
  height: 8.5px;
  margin-left: var(--spacer-10);
  fill: var(--grey-brand-grey-100, #fcfcfc);
  transform-origin: center;
  transition: transform 0.2s var(--ease-basic-butter);
}

.bulletItemCaretExpanded {
  transform: rotate(180deg);
}

.cta {
  margin-top: var(--spacer-32);
}

/**
 * Tooltip styles taken from PricingOverview / PricingCard and modified slighly
 * to make it work with these cards
 */
.cardDetailBulletSvgButton {
  position: absolute;
  top: calc(50% - 1rem);
  right: 0;
  left: auto;
  width: 2.4rem;
  height: 2.4rem;
  text-align: right;

  svg {
    path {
      fill: var(--alpha-brand-alpha-light-90, rgb(241 244 255 / 90%));
    }
  }
}

.cardDetailBulletSvg {
  position: relative;
  width: 1.6rem;
  height: auto;
}

.cardDetailBulletTooltip {
  composes: body from '~typo';
  position: absolute;
  z-index: var(--z-index-2);
  right: 1.5rem;
  width: 100%;
  padding: var(--spacer-12) var(--spacer-24) var(--spacer-12) var(--spacer-20);
  border: 1px solid var(--color-alpha-light-10);
  border-radius: 1rem 1rem 0;
  background: var(--color-grey-05);
  opacity: 0;
  pointer-events: none;
  transform: translateY(calc(-100% + 1rem));
  transition:
    opacity 0.2s var(--ease-out),
    visibility 0.2s;
  visibility: hidden;

  $triangleSize: 12px;

  &::after {
    position: absolute;
    width: 4rem;
    height: $triangleSize;
    border-radius: 1rem 1rem 0;
    background: var(--color-grey-05);
    content: '';
    inset: auto 0 0 auto;
  }

  [data-is-active] & {
    opacity: 1;
    visibility: visible;
  }
}

.cardDetailBulletTooltipText {
  display: flex;
  flex: 1;
}

.cardDetailBulletTooltipTail {
  position: absolute;
  top: 100%;
  right: -1px;
  width: $triangleSize;
  height: $triangleSize;
  border: 1px solid var(--color-alpha-light-10);
  background: var(--color-grey-05);
  transform: skewY(45deg) translateY(-50%);
}
